.quillEditor {
  max-width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.ql-mention-list-container {
  width: 100%;
}

.disable_editor .ql-editor {
  padding: 0;
  border: 1px solid #888888;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.5);
  background: #f7f7f7;
  flex: 80%;
}

.disable_editor .ql-toolbar {
  display: none;
}

.disable_editor .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 0;
}

.ql-editor ol,
.ql-editor ul {
  padding: 0 !important;
}

.ql-editor {
  font-size: 14px;
  color: #636262;
}

.ql-editor ol > li,
.ql-editor ul > li {
  margin: 7px 0 7px 0;
}

.disable_editor .ql-editor {
  padding: 6px 12px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #888;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-right: 1px solid #888;
  border-bottom: 1px solid #888;
  border-left: 1px solid #888;
}

.ql-snow a {
  color: #1236ff;
  background-color: #f0fbff;
}

.mention {
  background-color: #f0fbff;
  padding: 1px 1px;
  border-radius: 2px;
  text-decoration: none;
  color: #1236ff !important;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-height: 150px;
  overflow: auto;
}

.ql-mention-list-item {
  height: auto;
  line-height: 28px;
  font-size: 14px;
  border-bottom: 1px solid #f8f7f7;
}

.ql-mention-list-item.selected {
  background-color: #effafc;
}

.ql-mention-list-container {
  border: 1px solid #d9d6d6;
}
