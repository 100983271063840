.plan-date-picker {
  width: 8em;
  border: 1px solid #888;
  border-radius: 21px;
  height: 32px;
  color: #808080;
  background: url("date.png") .5em center no-repeat;
  background-size: 19px;
  padding: 0 0 0 2em;
}

.plan-date-picker[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f7f7f7;
}
.plan-date-picker[disabled]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.valid {
  background-color: #06b1dd;
  position: relative;
  top: 1px;
  right: 8px;
  border: 4px solid #06b1dd;
  border-radius: 8px;
  margin-left: 5px;
}

.valid span {
  color: white;
  margin: 2px;
  font-family: monospace;
  font-weight: bold;
  cursor: pointer;
}

.durationWraperPlanner {
  display: flex;
  margin-right: 1.5em;
  margin-bottom: 0.5em;
}
.freeDayWrapperPlanner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.total-days {
  margin: 0 0.5em 0.5em 0;
  cursor: not-allowed;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid grey;
  border-radius: 25px;
  text-align: center;
  padding: 0.35em 1em;
}
.editPlan {
  margin: 0 0.5em 0.5em 0;
  border: 1px solid grey;
  border-radius: 25px;
  padding: 0.35em 1em;
}
.editPlan[disabled] {
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}
.freeDropdownPlanner {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}
.percentDropDownPlanner {
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}
.status {
  margin-right: 0.5em;
}
.freeDayWrapperPlanner > .status {
  margin-bottom: 0.5em;
}
@media screen and (max-width: 550px) {
  .valid span {
    font-size: 10px;
  }
}
