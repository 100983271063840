.pending,
.approved,
.declined,
.valid span {
  color: white;
  margin: 2px;
  font-family: monospace;
  font-weight: bold;
}

.pending,
.approved,
.declined,
.valid {
  border-radius: 8px;
}

.pending,
.approved,
.declined {
  margin-left: 10px;
}

.pending {
  background-color: rgb(249, 201, 126);
  border: 4px solid rgb(249, 201, 126);
}

.approved {
  background-color: #66bb6a;
  border: 4px solid #66bb6a;
}

.declined {
  background-color: rgb(255, 0, 0);
  border: 4px solid rgb(255, 0, 0);
}
