.textCss {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.dayPicker {
  height: 44px;
  border-radius: 9px;
  border: 1px solid #cccccc;
  font-size: 16px;
  text-align: center;
}
.toDateSpan {
  margin-left: 3.3%;
}

.pickerStart {
  margin-left: 0.1%;
}

.chartContainer {
  width: 70%;
  height: 24rem;
  align-items: center;
  text-align: center;
  margin-left: 15%;
}
.reportDataContainer {
  width: 70%;
  margin-left: 15%;
  border: 1px solid #b3b3b3;
  padding: 10px;
  color: #808080;
  border-radius: 5px;
  background-color: #ffffff;
}

.reportText {
  font-size: 13px;
  display: block;
}

.report-sep {
  height: 1px;
  background: #b3b3b3;
  position: relative;
  width: 100%;
  margin: 7px 0;
  position: relative;
}
.report-sep-bullet {
  top: -1px;
  width: 3px;
  height: 3px;
  background: #b3b3b3;
  border-radius: 3px;
  position: absolute;
}
.report-sep-bullet.right {
  right: 0;
}
.report-sep-bullet.left {
  left: 0;
}

.reportContainer {
  position: relative;
}

.buttonExport {
  margin-left: 81.5%;
  margin-bottom: 10px;
  visibility: hidden;
}

.managerOptions .multiselect {
  border-radius: 20px !important;
  border-width: 2px;
  background: url("workers.png") #ffffff 10px center no-repeat !important;
  padding: 9px 16px 9px 40px !important;
  font-size: 18px;
}
.projects-bg .multiselect {
  background: url("projects.png") #ffffff 10px center no-repeat !important;
}

.multiselect-container > li > a {
  padding: 4px 0 !important;
}

.multiselect-active .multiselect {
  border-color: black;
}

.DayPickerInput-OverlayWrapper {
  z-index: 99999;
}
