.itemContainer {
  border: 1px solid;
  border-color: #b3b3b3;
  background: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 2em;
}

.headerStyle {
  font-size: 18px;
  color: red;
  margin-top: 5px;
}

.alertsStyles {
  padding-top: 0.5em;
  color: #a96e0a;
}

.managerButtSeparator li,
.managerButtSeparator button {
  text-transform: capitalize;
}

.generalOptions {
  display: flex;
  flex-direction: row;
}

.outOfficeButton {
  margin-left: 10.9%;
}

.listContainer {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
}

.hoursWorkedText {
  padding-left: 10px;
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: 2em;
  color: green;
}

.hoursInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: .5em;
}

.textInfoRow {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  flex-wrap: wrap;
  gap: .5em;
}
.dateWorkedHours {
  font-size: 11px;
}
.dateShow {
  color: #06b1dd;
  font-weight: 720;
  font-size: 16px;
  flex: 0.68;
  white-space: nowrap;
}
.entries {
  font-size: 11px;
}
@media screen and (min-width: 576px) {
  .dateShow {
    font-size: 21px;
  }
  .entries {
    font-size: 14px;
  }
  .dateWorkedHours {
    font-size: 14px;
  }
}
.allButtons {
  box-shadow: 0 8px 16px 0 rgba(255, 19, 194, 0.71),
    0 6px 20px 0 rgba(155, 55, 248, 0.8);
}

.rowContainer {
  display: flex;
  flex-direction: row;
}

.textInputInRowHour {
  width: 100%;
  margin-bottom: 1em;
}
.freeDayWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  margin-right: 2em;
  flex-wrap: wrap;
}
.dropdownList {
  align-self: self-start;
  margin-bottom: 1em;
}
.buttonsInRowHour {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.v-x-buttons {
  min-width: 24%;
}
.editorBox {
  margin-right: 3%;
}

.navOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.durationWraper {
  display: inline-flex;
  flex-wrap: nowrap;
  margin: 0 1em 1em 0;
}
.dropdownCalendar {
  align-self: flex-start;
}
.timeEnd {
  margin: 0 1%;
}
.timeStart {
  margin-right: 1%;
}
.duration {
  font-size: 13px;
  width: fit-content;
  align-self: center;
  margin: 0 1em;
}
.editPlanInput {
  border: 1px solid grey;
  border-radius: 25px;
  padding: 0.45em 1em;
  margin-right: 0.1em;
  width: 30%;
}
.editPlanInput[disabled] {
  cursor: not-allowed;
}
.buttonsInRowHour a.edit,
.buttonsInRowHour a.delete {
  width: 20px;
  height: 20px;
  display: block;
  background: no-repeat center center / contain;
}
.buttonsInRowHour a.edit {
  background-image: url("edit.png");
}
.buttonsInRowHour a.delete {
  background-image: url("delete.png");
  margin-left: .5em;
}
.buttonsInRowHour a.edit:hover,
.buttonsInRowHour a.delete:hover {
  opacity: 0.6;
}
@media screen and (min-width: 576px) {
  .duration {
    font-size: 14px;
    margin: 0 1em 0 2em;
  }
  .buttonsInRowHour a.edit,
  .buttonsInRowHour a.delete {
    width: 30px;
    height: 30px;
  }
}
.drawLine {
  width: 20px;
  color: #b3b3b3;
  text-align: center;
}
.freeDropdown {
  margin-right: 2.2em;
}

.precentDropDown {
  margin-right: 0.2em;
}
p.textDeclaration {
  margin: 0;
  margin-right: 1em;
}
.form-group {
  margin-bottom: 0;
}

.formControlText {
  font-size: 13px;
  display: block;
  width: 100%;
  padding: 5px 10px;
  line-height: 1.42857143;
  color: #808080;
  background-image: none;
  border: 1px solid #888;
  border-radius: 17px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

@media screen and (min-width: 576px) {
  .formControlText {
    font-size: 16px;
    padding: 6px 12px;
  }
}

.formControlMultiline {
  overflow: hidden;
  resize: none;
}

.formControlSelect {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #888;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.btn-Default {
  color: #333;
  background-color: transparent;
  border-color: #888;
}

.CancelButton {
  margin-left: 30px;
}

.ClearButoon {
  position: absolute;
  display: block;
}

.PercentageSelect {
  display: inline-block;
  margin-right: 3em;
  width: 6em;
  margin-bottom: 2em;
}

.TypeSelect {
  display: inline-block;
  margin-right: 6em;
}

.caret {
  width: 17px;
  height: 9px;
  background: url("arrow_small.png") 0 0 no-repeat;
  border: none;
}

.new-row, .new-free-day {
  width: 19px;
  height: 19px;
  background: no-repeat center center / contain;
  display: block;
}
.new-row {
  background-image: url("add.png");
}
.new-free-day {
  background-image: url("traveler.png");
  margin-right: 5px;
}
@media screen and (min-width: 576px) {
  .new-row, .new-free-day {
    width: 29px;
    height: 29px;
  }
}

.ant-time-picker-input,
.ant-time-picker-input[disabled],
.ant-time-picker-input[readonly] {
  border-radius: 20px;
  border-color: #888;
  color: #808080;
  text-align: center;
  padding-left: 10px !important;
}
.ant-time-picker-icon {
  left: 8px !important;
}

.timePickerInput,
.timePickerInput[disabled],
.timePickerInput[readonly] {
  font-size: 11px;
  height: 1.75em;
  width: 4em;
  border: 1px solid #888;
  border-radius: 20px;
  color: #808080;
  text-align: center;
}
.timePickerInput[disabled],
.timePickerInput[readonly] {
  cursor: not-allowed;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 576px) {
  .timePickerInput,
  .timePickerInput[disabled],
  .timePickerInput[readonly] {
    font-size: 14px;
    height: 32px;
    width: 130px;
    padding-left: 10px !important;
  }
}
.formControlText[disabled],
.formControlText[readonly] {
  cursor: not-allowed;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.5);
}

.save-button,
.cancel-button {
  height: 32px;
  width: 50%;
  border-radius: 20px;
  border: 1px solid red;
  background: url("v.png") center center no-repeat;
}
.save-button {
  background-image: url("v.png");
  border-color: #9ab933;
}
.cancel-button {
  margin-left: 10px;
  background-image: url("x.png");
  border-color: #ed7203;
}

.save-button:not([disabled]):hover,
.cancel-button:not([disabled]):hover {
  opacity: 0.6;
}
.save-button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}

.projects-dropdown {
  border-radius: 20px !important;
  border-width: 2px;
  background: #ffffff url("projects.png") 10px center no-repeat !important;
  padding-left: 40px !important;
}

.hours-dates-holder {
  border-radius: 20px;
  padding: 9px 10px 8px;
  border: 2px solid #cccccc;
  background-color: white;
  margin-top: 1em;
  min-width: 260px;
}
.hours-dates-holder > div > ul {
  left: -120px !important;
}
.hours-dates-holder[disabled] {
  pointer-events: none;
  opacity: 0.4;
}
.hours-date-picker {
  cursor: pointer;
  font-size: 14px;
  width: 6em;
  border: none;
  color: #808080;
  background: left center no-repeat;
}
@media screen and (min-width: 992px) {
  .hours-date-picker {
    width: 8em;
    background-image: url("date.png");
    padding-left: 2em;
  }
}
.hours-date-sep {
  width: 15px;
  background: #808080;
  height: 1px;
  top: -3px;
  position: relative;
  margin-right: 10px;
  display: inline-block;
}

.quickpick-dropdown {
  border: none;
  display: inline-block;
}

.percent {
  background: #ffffff url("percentage.png") 10px center no-repeat !important;
  padding-left: 30px !important;
}
.percent[disabled] {
  cursor: not-allowed;
  background-color: #f7f7f7 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.load_more {
  margin-left: 15%;
  margin-right: 15%;
  border: 1px solid;
  border-color: #b3b3b3;
  background: #ffffff;
  padding: 5px 10px;
  top: -30px;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
}

.deletedRow {
  background: #f1f0f0;
  opacity: 50%;
  border-radius: 8px;
  box-shadow: 0 0 0 5pt #f1f0f0;
  opacity: 0.3;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.deletedRow:hover {
  opacity: 100%;
}

.addedRow {
  background: #cfedf3;
  box-sizing: inherit;
  border-radius: 8px;
  box-shadow: 0 0 0 5pt #cfedf3;
}

.badge {
  color: white;
  margin: 2px;
  font-family: monospace;
  font-weight: bold;
  border-radius: 8px;
  margin: 2px 0;
  background-color: rgb(87, 112, 155);
  border: 4px solid rgb(87, 112, 155);
}

.empNameShow {
  color: #06b1dd;
  font-weight: bold;
  height: 34px;
  font-size: 21px;
  margin-right: 30px;
}
.adminDeclaration {
  background: #efffbc;
  box-sizing: inherit;
  border-radius: 8px;
  box-shadow: 0 0 0 5pt #efffbc;
  margin-bottom: 1.5em;
}

@media screen and (max-width: 620px) {
  .v-x-buttons {
    min-width: 18%;
  }
}
