.Header-top {
	background: url('./bg.png') no-repeat 50%;
  border-bottom: 3px solid orange;
}

.user-image {
	width: 2.2em;
  height: 2.2em;
  background: no-repeat center center / contain;
  border: 3px solid orange;
  border-radius: 50%;
}

.header-btn {
  margin-left: .3em;
}

.header-btn:hover {
  opacity: .8;
}

.Header-bottom {
  border-bottom: 3px solid grey;
}
