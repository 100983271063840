.managerOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.managerOptions > span {
  margin-top: 1em;
  margin-right: 0.5em;
}

.allDates {
  margin: 1em 1em 0 1em;
}

.allDates input[type="checkbox"] {
  transform: scale(1.5);
  padding: 10px;

  margin-right: 7px;
}

.allDates input[disabled] + label {
  opacity: 0.4;
}

.allDates label {
  font-weight: 400;
  font-size: 14px;
}

.picky {
  width: 100%;
  background-color: white;
  border: 2px solid #cccccc;
  border-radius: 20px;
  min-width: 260px;
  padding: 5px 6px 4px;
  color: #808080;
  box-shadow: none;
  outline: none;
}

.picky_disabled {
  background-color: #f7f7f7;
  border: 2px solid #cccccc;
  color: #808080;
}

.picky .picky__input {
  border: none;
  outline: none;
  padding: 0px 3px 1px;
}

.picky_disabled .picky__input {
  background-color: #f7f7f7;
  color: #a8a8a8;
}

.hr-options {
  display: block;
  width: 70%;
  margin-left: 15%;
  clear: both;
  text-align: center;
  margin-top: 10px;
}

.picky_audit {
  border-radius: 5px;
  min-width: 160px;
  width: 130px !important;
  position: relative;
  padding: 0;
  padding-left: 0px;
  height: 50%;
  border: 2px solid #8591b2;
  margin-top: 1em;
  margin-right: 1em;
}

.picky_audit input[type="checkbox"],
.picky_audit input[type="radio"] {
  display: none;
}

.picky_audit .picky__input {
  width: 100%;
  text-align: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 38px;
  background: #6d80a7;
  color: white;
  font-family: sans-serif;
  font-size: 18px;
}

.picky_audit .picky__dropdown .option,
.picky__dropdown li {
  list-style: none;
  background-color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  border-top: 1px solid white;
  text-align: center;
  font-size: 15px;
}

.picky_audit .picky__dropdown {
  overflow: hidden !important;
}

/* Prevent picky's arrow from covering the text: */
.picky > button > span {
  display: inline-block;
  max-width: calc(260px - 2.5em);
  height: 1.5em;
  overflow: hidden;
}
